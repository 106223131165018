<template>
  <div class="my module-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "My",
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  // created () {},
  // beforeMount () {},
  // mounted () {},
  // components: {},
  // watch: {}
};
</script>

<style lang="scss" scoped></style>
