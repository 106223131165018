<template>
    <div class="page">
        <el-form class="update-pwd private as-page pd4"
            label-width="100px"
            label-suffix=":"
            :model="PB"
            :rules="rules"
            v-loading="submitDisabled"
            ref="form"
        >

            <el-form-item label="真实姓名" prop="name">
                <el-input v-model.trim="PB.name" class="width2"></el-input>
            </el-form-item>

            <el-form-item label="账号">
                <el-input :value="userInfo.account || 'user'" disabled class="width2"></el-input>
            </el-form-item>

            <el-form-item label="新密码" prop="password">
                <el-input show-password v-model.trim="PB.password" class="width2"></el-input>
            </el-form-item>

            <el-form-item label="确认密码" prop="again">
                <el-input show-password v-model.trim="PB.again" class="width2"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" :disabled="submitDisabled" @click="handleSubmitButton">保存</el-button>
                <el-button :disabled="submitDisabled" @click="handleNavigationBack">返回</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import forEdit from "@/mixin/forEdit";
import MD5 from "blueimp-md5";

export default {
    name: "UpdatePwd",
    mixins: [forEdit],

    data() {
        return {
            PB: {
                name: "",     // 真实姓名
                password: "", // 密码
                again: "",    // 确认密码
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入真实姓名"
                },
                password: [
                    { required: true, message: "请输入新密码" },
                    {
                        validator (rule, value, cb) {
                            const fine = value === "" || /^[0-9A-Za-z]{6,20}$/.test(value);
                            if (fine) cb();
                            else cb("密码为6-20位的数字或字母")
                        }
                    }
                ],
                again: [
                    { required: true, message: "请再次输入新密码" },
                    {
                        validator (rule, value, cb) {
                            const fine = value === "" || /^[0-9A-Za-z]{6,20}$/.test(value);
                            if (fine) cb();
                            else cb("密码为6-20位的数字或字母")
                        }
                    }
                ]
            }
        };
    },

    methods: {
        

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()

            .then(() => {
                console.log(this.PB.password, this.PB.again, this.PB.password === this.PB.again)
                if (this.PB.password !== this.PB.again) {
                    return Promise.reject("两次密码输入不一致")
                }
            })

            .then(() => {
                this.posting = true;
                const {name, password} = this.PB
                return this.$axios({
                    url: "api/employee/updatePassword",
                    method: "post",
                    data: {
                        empid: this.userInfo.empid,
                        name,
                        password: MD5(password)
                    }
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "修改密码成功，请重新登录" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });

                if (success) this.$store.commit("clearUserInfo");
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason || "操作失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    created () {
        this.PB.name = this.userInfo.name
    },
};
</script>

<style lang="scss" scoped></style>
